import '../App.css'
import React, { useState, useEffect } from 'react'
import {
  Grid,
  Paper,
  Typography,
  Slide,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'
import LambdaFetch from '../functions/FetchFromLambda'
import { Box, ThemeProvider, createTheme } from '@mui/system'
import { styled } from '@mui/material/styles'
import { currencyFormat, dateFormat } from '../utils/formatter'
import { makeStyles, withStyles } from 'tss-react/mui'

const currencyTextFormat = value => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2 // (causes 2500.99 to be printed as $2,501)
  })
  return formatter.format(value)
}

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: props => 'green'
  },
  paper: {
    padding: 1
  },
  table: {
    root: {
      border: '2px solid black'
    }
  },
  tableRow: {
    borderBottom: '1px solid black'
    // backgroundColor: 'black'
  }
}))

const StyledTable = styled(Table)(({ theme }) => ({
  border: '2px solid black'
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px 0px 1px 1px solid black',
  fontSize: '11px'
}))

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  borderRight: '1px solid black',
  borderTop: '1px solid black',
  borderBottom: '1px solid black',
  fontSize: '8px',
  padding: 0,
  lineHeight: '10px'
}))

const StyledTableSubHeaderCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '1px solid black',
  fontSize: '12px',
  lineHeight: '14px'
}))

const StyledTableHeaderRow = styled(TableRow)(({ theme }) => ({
  // borderTop: '2px solid black',
  // borderLeft: '2px solid black',
  // borderBottom: '2px solid black',
}))

const StyledTableBodyRow = styled(TableRow)(({ theme }) => ({
  borderLeft: '1px solid black',
  borderRight: '1px solid black',
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover
  // },
  ':last-child': {
    borderBottom: '1px solid black'
  }
}))

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.caption,
  padding: theme.spacing(0.5),
  color: theme.palette.black,
  height: '95%',
  border: 'solid black 1px',
  elevation: 0,
  verticalAlign: 'middle'
}))

const ItemTop = styled(Box)(({ theme }) => ({
  ...theme.typography.caption,
  padding: theme.spacing(0.5),
  color: theme.palette.black,
  height: '95%',
  elevation: 0,
  position: 'relative'
}))

function ArInvoiceDetails (props) {
  const classes = useStyles({ primaryColor: 'green' })
  const { credentials } = props.fetchInitialData

  const [state, setState] = useState({
    invoiceHeaders: null,
    invoiceRows: []
  })

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const company = urlParams.get('company')
    const prefix = urlParams.get('prefix')
    const invoice = urlParams.get('invoice')
    fetchInvoiceDetails({ company, prefix, invoice })
  }, [])

  const fetchInvoiceDetails = async props => {
    try {
      const resp = await LambdaFetch(
        'ar-invoice',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'invoice_detail_read',
          company: props.company,
          prefix: props.prefix,
          invoice: props.invoice
        }),
        '',
        credentials
      )
      setState({
        ...state,
        invoiceHeaders: resp.data.invoiceDetailHeaders
          ? resp.data.invoiceDetailHeaders[0]
          : null,
        invoiceRows: resp.data.invoiceDetailLines
      })
    } catch (e) {
      setState({ ...state, isLoading: false })
    }
  }

  if (state.invoiceHeaders == null) return null
  const { invoiceHeaders, invoiceRows } = state
  const rows = invoiceRows.reduce((acc, cur) => {
    if (cur.LINE_NUMBER === 0) return acc
    let currentRow = [cur]
    if (cur.TAX_AMT_01 && parseInt(cur.TAX_AMT_01) != 0) {
      currentRow = [
        ...currentRow,
        {
          TYPE: 'TAX',
          DESCRIPTION: `${cur.TAX_CODE_01} TAX`,
          AMOUNT: cur.TAX_AMT_01
        }
      ]
    }
    acc = [...acc, ...currentRow]
    return acc
  }, [])

  const lineItemTotal = invoiceRows.reduce((acc, cur) => {
    if (cur.LINE_NUMBER !== 0 && !((cur.ORDER_FIELD === 2 && cur.LINE_NUMBER > 0))) {
      acc += parseFloat(cur.AMOUNT)
    }
    return acc
  }, 0)

  const discountTotal = invoiceRows.reduce((acc, cur) => {
    if (cur.ORDER_FIELD === 2 && cur.LINE_NUMBER > 0) {
      acc += parseFloat(cur.AMOUNT)
    }
    return acc
  }, 0)

  const taxTotal = invoiceRows.reduce((acc, cur) => {
    if (
      cur.TAX_AMT_01 &&
      parseInt(cur.TAX_AMT_01) != 0 &&
      cur.NO_CHARGE_FL !== 'Y'
    ) {
      acc += parseFloat(cur.TAX_AMT_01)
    }
    return acc
  }, 0)

  const shipping = invoiceRows.find(cur => cur.LINE_NUMBER === 0)
  const shippingTotal = shipping ? shipping.AMOUNT : 0

  return (
    <div>
      <div style={{ paddingTop: '1rem', margin: 'auto' }}>
        <Box
          sx={{
            p: 1,
            width: '98vw',
            maxWidth: '1200px',
            m: 'auto'
          }}
        >
          <Grid
            container
            spacing={0.5}
            direction='row'
            justify='flex-start'
            alignItems='stretch'
          >
            <Grid item xs={4}>
              <ItemTop>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: 600,
                      border: '1px solid black',
                      height: '30px',
                      width: '120px',
                      textAlign: 'center',
                      marginRight: '30px'
                    }}
                  >
                    INVOICE
                  </div>
                  <div>
                    {/* <img src='/tabacaleralogo.png' height='36px' /> */}
                  </div>
                </div>
                <div>
                  <b>SHIP TO</b>
                </div>
                <div>{invoiceHeaders.SHIP_TO_NAME}</div>
                <div>{`${invoiceHeaders.SHIP_TO_ADDR1} ${invoiceHeaders.SHIP_TO_CITY}, ${invoiceHeaders.SHIP_TO_STATE} ${invoiceHeaders.SHIP_TO_ZIP}`}</div>
                <div style={{ marginTop: '0.75rem' }}>
                  <div>
                    <b>SHIP FROM</b>
                  </div>

                  <div>{`${invoiceHeaders.CITY_ADDR5}, ${invoiceHeaders.STATE_PROV} ${invoiceHeaders.POSTAL_CODE}`}</div>
                </div>
              </ItemTop>
            </Grid>

            <Grid item xs={4}>
              <ItemTop>
                <div
                  style={{
                    height: '42px'
                  }}
                >
                  <div className='invoiceRightHeaders'>ADMINISTERED BY</div>
                  <div className='invoiceRightHeaders'>
                    5900 NORTH ANDREWS AVENUE, FORT LAUDERDALE, FL 33309-2369
                    USA
                  </div>
                </div>
                <div>
                  <b>BILL TO</b>
                </div>
                <div>{invoiceHeaders.SHIP_TO_NAME}</div>
                {`${invoiceHeaders.ADDR1} ${invoiceHeaders.CITY}, ${invoiceHeaders.STATE} ${invoiceHeaders.ZIP}`}

                <div
                  style={{
                    position: 'absolute',
                    bottom: '-1px',
                    width: '95%',
                    height: '30px',
                    border: '1px solid black',
                    textAlign: 'center'
                  }}
                >
                  <div className='invoiceRightHeaders'>
                    SALES REPRESENTATIVE
                  </div>
                  <div>{invoiceHeaders.NAME}</div>
                </div>
              </ItemTop>
            </Grid>

            <Grid item xs={4}>
              <Grid container spacing={0} className='wrapper'>
                <Grid className='invoiceRightSubItem' item xs={12}>
                  <div style={{ textAlign: 'center', letterSpacing: '.60rem' }}>
                    <b>INVOICE</b>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid className='invoiceRightSubItem' item xs={5}>
                      <div className='invoiceRightHeaders'>NUMBER</div>
                      <div className='invoiceRightHeaderValues'>{`${
                        invoiceHeaders.INVC_PREFIX
                      }${'00000000'
                        .concat(invoiceHeaders.INVC_NUMBER)
                        .slice(-8)}`}</div>
                    </Grid>
                    <Grid
                      className='invoiceRightSubItem'
                      style={{ borderLeft: 0, borderRight: 0 }}
                      item
                      xs={3}
                    >
                      <div className='invoiceRightHeaders'>PAGE</div>
                      <div className='invoiceRightHeaderValues'>1</div>
                    </Grid>
                    <Grid className='invoiceRightSubItem' item xs={4}>
                      <div className='invoiceRightHeaders'>DATE</div>
                      <div className='invoiceRightHeaderValues'>
                        {dateFormat(invoiceHeaders.INVOICE_DATE)}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid className='invoiceRightSubItem' item xs={5}>
                      <div className='invoiceRightHeaders'>ACCOUNT NUMBER</div>
                      <div className='invoiceRightHeaderValues'>
                        {invoiceHeaders.CUSTOMER}
                      </div>
                    </Grid>
                    <Grid
                      className='invoiceRightSubItem'
                      style={{ borderLeft: 0, borderRight: 0 }}
                      item
                      xs={3}
                    >
                      <div className='invoiceRightHeaders'>SHIP #</div>
                      <div className='invoiceRightHeaderValues'>
                        {invoiceHeaders.SHIP_TO}
                      </div>
                    </Grid>
                    <Grid className='invoiceRightSubItem' item xs={4}>
                      <div className='invoiceRightHeaders'>CLASS CODE</div>
                      <div className='invoiceRightHeaderValues'>
                        {invoiceHeaders.CLASS_CODE}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid className='invoiceRightSubItem' item xs={12}>
                      <div className='invoiceRightHeaders'>
                        CUSTOMER PURCHASE ORDER NUMBER
                      </div>
                      <div className='invoiceRightHeaderValues'>
                        {invoiceHeaders.CUST_PO_NBR}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid className='invoiceRightSubItem' item xs={12}>
                      <div className='invoiceRightHeaders'>
                        DATE ORDER RECEIVED
                      </div>
                      <div className='invoiceRightHeaderValues'>
                        {dateFormat(invoiceHeaders.ORDER_DATE)}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid
                      className='invoiceRightSubItem'
                      style={{ borderRight: 0 }}
                      item
                      xs={6}
                    >
                      <div className='invoiceRightHeaders'>
                        OUR ORDER NUMBER
                      </div>
                      <div className='invoiceRightHeaderValues'>
                        {invoiceHeaders.ORDER_NBR}
                      </div>
                    </Grid>
                    <Grid className='invoiceRightSubItem' item xs={6}>
                      <div className='invoiceRightHeaders'>SHIPMENT NUMBER</div>
                      <div className='invoiceRightHeaderValues'>
                        {invoiceHeaders.SHIPMENT_NBR}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <span className='subHeaderNumbers'>CUSTOMER SERVICE TEL:</span>
              <span className='subHeaderNumberValues'> 800-222-6644</span>
            </Grid>
            <Grid item xs={4}>
              <span className='subHeaderNumbers'>FAX:</span>
              <span className='subHeaderNumberValues'> 800-962-7552</span>
            </Grid>
            <Grid item xs={4}>
              <span className='subHeaderNumbers'>SHIPPED VIA:</span>
              <span className='subHeaderNumberValues'>
                {` ${invoiceHeaders.VENDOR_VNAME}`}
              </span>
            </Grid>

            <Grid item xs={12}>
              <StyledTable size='small'>
                <TableHead style={{ border: '1px solid black' }}>
                  <StyledTableHeaderRow>
                    <StyledTableHeaderCell
                      style={{ borderLeft: '1px solid black' }}
                      rowSpan={2}
                      align='center'
                    >
                      DESCRIPTION
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      rowSpan={2}
                      style={{ width: '4%' }}
                      align='center'
                    >
                      UOM OF QUANTITY
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      style={{ width: '4%' }}
                      align='center'
                      colSpan={2}
                    >
                      ORDERED
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      style={{ paddingBottom: 0, width: '4%' }}
                      align='center'
                      colSpan={2}
                    >
                      SHIPPED
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      style={{ paddingBottom: 0, width: '4%' }}
                      align='center'
                      colSpan={2}
                    >
                      B=BO, C=CANCELED, O=FUTURE, H=CR, HOLD
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      rowSpan={2}
                      style={{ width: '5%' }}
                      align='center'
                    >
                      PRICE
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      rowSpan={2}
                      style={{ width: '5%' }}
                      align='center'
                    >
                      PER
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      rowSpan={2}
                      style={{ width: '5%' }}
                      align='center'
                    >
                      AMOUNT
                    </StyledTableHeaderCell>
                  </StyledTableHeaderRow>

                  <TableRow>
                    <StyledTableSubHeaderCell
                      style={{ fontSize: '8px', padding: 0 }}
                      align='center'
                    >
                      CASES
                    </StyledTableSubHeaderCell>
                    <StyledTableSubHeaderCell
                      style={{
                        fontSize: '8px',
                        padding: 0,
                        borderRight: '1px solid black'
                      }}
                      align='center'
                    >
                      QUANTITY
                    </StyledTableSubHeaderCell>
                    <StyledTableSubHeaderCell
                      style={{ fontSize: '8px', padding: 0 }}
                      align='center'
                    >
                      CASES
                    </StyledTableSubHeaderCell>
                    <StyledTableSubHeaderCell
                      style={{
                        fontSize: '8px',
                        padding: 0,
                        borderRight: '1px solid black'
                      }}
                      align='center'
                    >
                      QUANTITY
                    </StyledTableSubHeaderCell>

                    <StyledTableSubHeaderCell
                      style={{ fontSize: '8px', padding: 0 }}
                      align='center'
                    >
                      CASES
                    </StyledTableSubHeaderCell>
                    <StyledTableSubHeaderCell
                      style={{
                        fontSize: '8px',
                        padding: 0,
                        borderRight: '1px solid black'
                      }}
                      align='center'
                    >
                      QUANTITY
                    </StyledTableSubHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((r, index) => {
                    return (
                      <StyledTableBodyRow
                        className={classes.tableRow}
                        key={`${index}`}
                      >
                        <StyledTableCell align='left'>
                          {r.TYPE === 'TAX'
                            ? `${r.DESCRIPTION}`
                            : `${r.BRAND} ${r.DESCRIPTION} Item ${r.ITEM} UPC ${
                                r.UPC ? r.UPC : ''
                              }`}
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          {r.UOM_SELL}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '5%' }} align='right'>
                          {r.ORDERED_CASES ? r.ORDERED_CASES : ''}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '5%' }} align='right'>
                          {r.ORDERED_QUANTITY}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '5%' }} align='right'>
                          {r.SHIPPED_CASES ? r.SHIPPED_CASES : ''}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '5%' }} align='right'>
                          {r.SHIPPED_QUANTITY}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '5%' }} align='right'>
                          {r.BO_CASES ? r.BO_CASES : ''}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: '5%' }} align='right'>
                          {r.BO_QUANTITY ? r.BO_QUANTITY : ''}
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                          {r.TYPE === 'TAX' || r.UNIT_PRICE === ''
                            ? ''
                            : `${currencyTextFormat(r.UNIT_PRICE)}`}
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          {r.UOM_SELL}
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                          {currencyTextFormat(r.AMOUNT)}
                        </StyledTableCell>
                      </StyledTableBodyRow>
                    )
                  })}

                  <StyledTableBodyRow
                    className={classes.tableRow}
                    key={`linetotal`}
                  >
                    <StyledTableCell align='left' colSpan={10}>
                      {`INVOICE LINE TOTAL`}
                    </StyledTableCell>

                    <StyledTableCell align='right'>
                      {/* <b>{currencyTextFormat(invoiceHeaders.LINE_AMT_TOT)}</b> */}
                      <b>{currencyTextFormat(lineItemTotal)}</b>
                    </StyledTableCell>
                  </StyledTableBodyRow>

                  {!!discountTotal && (
                    <>
                      <StyledTableBodyRow
                        className={classes.tableRow}
                        key={`discountlinetotal`}
                      >
                        <StyledTableCell align='left' colSpan={10}>
                          {`OFF INVOICE DISCOUNT TOTAL`}
                        </StyledTableCell>

                        <StyledTableCell align='right'>
                          <b>{discountTotal}</b>
                        </StyledTableCell>
                      </StyledTableBodyRow>

                      <StyledTableBodyRow
                        className={classes.tableRow}
                        key={`netlinetotal`}
                      >
                        <StyledTableCell align='left' colSpan={10}>
                          {`INVOICE LINE NET TOTAL`}
                        </StyledTableCell>

                        <StyledTableCell align='right'>
                          <b>
                            {currencyTextFormat(lineItemTotal + discountTotal)}
                          </b>
                        </StyledTableCell>
                      </StyledTableBodyRow>
                    </>
                  )}

                  {!!shipping && (
                    <StyledTableBodyRow
                      className={classes.tableRow}
                      key={`shippinglinetotal`}
                    >
                      <StyledTableCell align='left' colSpan={10}>
                        {shipping.DESCRIPTION}
                      </StyledTableCell>

                      <StyledTableCell align='right'>
                        <b>{shipping.AMOUNT}</b>
                      </StyledTableCell>
                    </StyledTableBodyRow>
                  )}
                  {!!invoiceHeaders.LINE_DISC_TOT && (
                    <StyledTableBodyRow
                      className={classes.tableRow}
                      key={`taxline`}
                    >
                      <StyledTableCell align='left' colSpan={10}>
                        {`Altadis USA must collect and remit OTP Tax for`}
                      </StyledTableCell>

                      <StyledTableCell align='right'>
                        <b>{currencyTextFormat(taxTotal)}</b>
                      </StyledTableCell>
                    </StyledTableBodyRow>
                  )}
                </TableBody>
              </StyledTable>
            </Grid>

            <Grid item xs={9}>
              <Item>
                <div style={{ fontSize: '10px', lineHeight: '10px' }}>
                  NO RETURN MERCHANDISE ACCEPTED WITHOUT OUR OFFICIAL
                  AUTHORIZATION. THE TOBACCO PRODUCTS DESCRIBED HEREIN ARE SOLD
                  WITHOUT PAYMENT OF STATE OR LOCAL TAXES UNLESS SUCH TAXES ARE
                  SEPARATELY STATED ON INVOICE.{' '}
                </div>
              </Item>
            </Grid>

            <Grid item xs={3}>
              <Item style={{ textAlign: 'center', lineHeight: '12px' }}>
                <div className='invoiceRightHeaders'>PAYMENT TERMS</div>
                <div>{invoiceHeaders.DESC_01}</div>
              </Item>
            </Grid>

            <Grid item xs={3}>
              <div style={{ fontSize: '10px' }}>
                ALL SHIPMENTS FOB: OUR FACTORY AT CONSIGNEE RISK. NO CLAIMS
                CONSIDERED UNLESS MADE WITHIN FIVE DAYS OF RECEIPTS OF GOODS.
              </div>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <div
                  className='invoiceRightHeaders'
                  style={{ textAlign: 'center' }}
                >
                  PLEASE REMIT TO
                </div>
                <div style={{ textAlign: 'center', fontWeight: 500 }}>
                  Altadis USA 5900 N.Andrews Ave Suite 600 Fort Lauderdale, FL
                  33309
                </div>
              </Item>
            </Grid>

            <Grid item xs={5}>
              <Item style={{ border: '2px solid black' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-evenly' }}
                >
                  <div>
                    <span style={{ fontWeight: 600 }}>INVOICE TOTAL</span>{' '}
                    {/* <span>{`$${currencyTextFormat(
                      parseFloat(invoiceHeaders.LINE_AMT_TOT) +
                        parseFloat(invoiceHeaders.LINE_DISC_TOT)
                    )}`}</span> */}
                    <span>
                      {`$${currencyTextFormat(
                        lineItemTotal + discountTotal + shippingTotal + taxTotal
                      )}`}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 600 }}>DUE</span>{' '}
                    <span>{dateFormat(invoiceHeaders.DUE_DATE)}</span>
                  </div>
                </div>
                {!!invoiceHeaders.DISCOUNT && (
                  <div
                    style={{
                      borderTop: 'solid black 1px',
                      textAlign: 'center'
                    }}
                  >
                    <b>{`$${currencyTextFormat(
                      (lineItemTotal +
                        discountTotal +
                        shippingTotal +
                        taxTotal) *
                        ((100 - parseFloat(invoiceHeaders.DISCOUNT)) / 100)
                    )}`}</b>
                  </div>
                )}
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
}

export default ArInvoiceDetails
